<template>
	<div class="keyword">
		<TrackCard :trackProps="trackProps">
			<ProgressBar
				v-if="isAnswering"
				progressBarColor="var(--tag-green-primary)"
				progressBarBg="#C5F0E6"
				:value="totalLength"
				:maxValue="keywords.length"
			/>
			<div class="keywords-wrapper">
				<div v-for="(keyword, index) in keywords" :key="index">
					<v-chip
						label
						class="keyword-label"
						:class="{ 'bg-tag-green-primary': keyword.enabled, 'bg-tag-disabled': !keyword.enabled }"
						text-color="white"
						>{{ keyword.label }}</v-chip
					>
				</div>
			</div>
		</TrackCard>
	</div>
</template>

<script>
import ProgressBar from '@/components/progress-bar/ProgressBar.vue';
import TrackCard from '@/components/track/track-card/TrackCard.vue';
import { findKeywords } from '@/utils/text.js';

export default {
	name: 'Keyword',
	components: { TrackCard, ProgressBar },
	data() {
		return {
			isAnswering: false,
			totalLength: 0,
			keywords: [],
		};
	},
	props: { userAnswers: Array, track: Object, sessionBlock: Object },
	computed: {
		trackProps() {
			return {
				label: 'Mots-clés',
				labelColor: 'var(--tag-green-primary)',
				bgClass: 'bg-tag-green-secondary',
				headerScore: !this.isAnswering ? this.keywords.length : `${this.totalLength} / ${this.keywords.length}`,
				headerScoreText: !this.isAnswering ? 'mots-clés à écrire' : '',
			};
		},
	},
	watch: {
		userAnswers(newAnswers) {
			this.isAnswering = newAnswers.length > 0;
			if (this.isAnswering) {
				const foundKeyWords = findKeywords(newAnswers[0], this.track.track_extension.keywords_expected);
				this.keywords = this.track.track_extension.keywords_expected.map((keyword) => ({
					label: keyword,
					enabled: foundKeyWords.includes(keyword),
				}));
				this.totalLength = this.keywords.filter((keyword) => keyword.enabled).length;
			} else {
				this.keywords = [];
				this.totalLength = 0;
			}
		},
	},
	mounted() {
		this.keywords = this.track.track_extension.keywords_expected.map((keyword) => ({
			label: keyword,
			enabled: false,
		}));
	},
};
</script>

<style lang="scss" scoped>
.keyword {
	.keywords-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 6px;
		.keyword-label {
			height: 22px;
			font-size: 12px;
			font-weight: bold;
		}
	}
}
</style>
