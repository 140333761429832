<template>
	<div class="track-final-buttons flex-wrap d-flex justify-end">
		<template>
			<v-btn dark :loading="submitLoading" :to="nextTrackRouteParams" class="black btn-action next-btn">
				<span class="white--text font-weight-bold">{{ nextButtonText }}</span>
			</v-btn>
		</template>

		<PromptAssistantDialog
			:session="session"
			:dialog.sync="dialog"
			:track="track"
			:promptType="promptExplanationType"
			:formationProgress="formationProgress"
			:previousExplanation="previousExplanation"
		>
			<template v-slot:activator="{ attrs, on }">
				<button
					class="ortho-white-button font-weight-bold"
					v-bind="attrs"
					v-on="on"
					@click="togglePromptAssistantDialog"
				>
					Je ne comprends pas
				</button>
			</template>
		</PromptAssistantDialog>

		<button v-if="sessionBlock.type == 'training'" class="ortho-white-button redo-btn" @click="redoTrack">
			<v-icon color="black" size="25px">mdi-refresh</v-icon>
		</button>
	</div>
</template>

<script>
import PromptAssistantDialog from '@/components/track/prompt-assistant-dialog/PromptAssistantDialog.vue';
import {
	PROMPT_EXPLANATION_TYPE,
	SESSION_BLOCK_QUIZ_TYPE,
	SESSION_BLOCK_REVIEW_TYPE,
	SESSION_BLOCK_TRAINING_TYPE,
} from '../../constants/redaction';

export default {
	name: 'TrackFinalButtons',
	components: { PromptAssistantDialog },
	props: {
		track: Object,
		formationProgress: Object,
		session: Object,
		sessionBlock: Object,
		submitLoading: Boolean,
		previousExplanation: String,
	},
	computed: {
		isTheLastTrack() {
			const questionnaire = this.getCurrentQuestionnaire();
			if (!questionnaire) return false;

			const lastTrack = questionnaire.tracks.sort((a, b) => b.position - a.position)[0];
			return lastTrack._id === this.track._id;
		},
		nextTrackRouteParams() {
			const { sessionId, formationId } = this.$route.params;

			// By default, get the current sessionBlockId
			let sessionBlockId = this.sessionBlock._id;
			let nextTrack;

			if (this.$route.query.fromPage === 'session-result') {
				return this.resultsRouteParams;
			}

			if (!this.isTheLastTrack) {
				// CASE NOT THE LAST TRACK
				// Navigate to the same questionnaire

				const questionnaire = this.getCurrentQuestionnaire();
				const currentTrack = questionnaire.tracks.find((t) => t._id === this.track._id);

				// The next track is the track with position + 1
				nextTrack = questionnaire.tracks.find((t) => t.position === currentTrack.position + 1);
			} else {
				// CASE LAST TRACK
				// Navigate to the next questionnaire on same session block
				const nextQuestionnaire = this.getNextQuestionnaire();

				if (nextQuestionnaire) {
					// The next track is the first track in the next questionnaire
					nextTrack = nextQuestionnaire.tracks.sort((a, b) => a.position - b.position)[0];
				} else {
					// If last track on last questionnaire in the current session block
					// Navigate to the next session block

					// SessionBlock type is 'TRAINING'
					if (this.sessionBlock.type === this.sessionBlockTrainingType) {
						const nextSessionBlock = this.getFirstQuizBlock() || this.getFirstReviewBlock();

						if (nextSessionBlock) {
							nextTrack = nextSessionBlock.questionnaires
								.sort((a, b) => a.position - b.position)[0]
								.tracks.sort((a, b) => a.position - b.position)[0];
							sessionBlockId = nextSessionBlock._id;
						} else {
							return this.resultsRouteParams;
						}
					}

					// SessionBlock type is 'QUIZ'
					if (this.sessionBlock.type === this.sessionBlockQuizType) {
						const nextSessionBlock = this.getNextQuizBlock(sessionBlockId) || this.getFirstReviewBlock();

						if (nextSessionBlock && nextSessionBlock._id !== sessionBlockId) {
							nextTrack = nextSessionBlock.questionnaires
								.sort((a, b) => a.position - b.position)[0]
								.tracks.sort((a, b) => a.position - b.position)[0];

							sessionBlockId = nextSessionBlock._id;
						} else {
							return this.resultsRouteParams;
						}
					}

					// SessionBlock type is 'REVIEW'
					if (this.sessionBlock.type === this.sessionBlockReviewType) {
						const nextSessionBlock = this.getNextReviewBlock(sessionBlockId);

						if (nextSessionBlock && nextSessionBlock._id !== sessionBlockId) {
							nextTrack = nextSessionBlock.questionnaires
								.sort((a, b) => a.position - b.position)[0]
								.tracks.sort((a, b) => a.position - b.position)[0];

							sessionBlockId = nextSessionBlock._id;
						} else {
							return this.resultsRouteParams;
						}
					}
				}
			}

			return {
				name: 'TrackPage',
				params: {
					sessionId,
					formationId,
					sessionBlockId,
					trackId: nextTrack._id,
				},
			};
		},
		resultsRouteParams() {
			const { sessionId, formationId } = this.$route.params;
			return {
				name: 'SessionResultPage',
				params: { formationId, sessionId },
				query: { notRedo: this.session._id === this.formationProgress.formation.last_session_id },
			};
		},
		nextButtonText() {
			const isTraining = this.sessionBlock.type === this.sessionBlockTrainingType;
			const isQuiz = this.sessionBlock.type === this.sessionBlockQuizType;
			const isReview = this.sessionBlock.type === this.sessionBlockReviewType;
			const noNextQuestionnaire = !this.getNextQuestionnaire();
			const sessionBlockId = this.$route.params.sessionBlockId;
			const nextSessionBlockForTraining = this.getFirstQuizBlock() || this.getFirstReviewBlock();
			const nextSessionBlockForQuiz = this.getNextQuizBlock(sessionBlockId) || this.getFirstReviewBlock();

			const nextSessionBlockForReview = this.getNextReviewBlock(sessionBlockId);

			if (this.$route.query.fromPage === 'session-result') {
				return 'Voir mes résultats';
			}

			if (isTraining && this.isTheLastTrack && noNextQuestionnaire) {
				if (!nextSessionBlockForTraining) {
					return 'Voir mes résultats';
				}
				return nextSessionBlockForTraining.type === 'quiz' ? 'Passer au Quiz' : 'Passer à la révision';
			}

			if (isQuiz && this.isTheLastTrack && noNextQuestionnaire) {
				if (!nextSessionBlockForQuiz || nextSessionBlockForQuiz._id === sessionBlockId) {
					return 'Voir mes résultats';
				}

				return nextSessionBlockForQuiz.type === 'quiz' ? 'Passer au Quiz suivant' : 'Passer à la révision';
			}

			if (isReview && this.isTheLastTrack && noNextQuestionnaire) {
				if (!nextSessionBlockForReview || nextSessionBlockForReview._id === sessionBlockId) {
					return 'Voir mes résultats';
				}
				return 'Passer à la révision suivante';
			}

			return 'Question suivante';
		},
	},
	data() {
		return {
			promptExplanationType: PROMPT_EXPLANATION_TYPE,
			dialog: false,
			sessionBlockTrainingType: SESSION_BLOCK_TRAINING_TYPE,
			sessionBlockQuizType: SESSION_BLOCK_QUIZ_TYPE,
			sessionBlockReviewType: SESSION_BLOCK_REVIEW_TYPE,
		};
	},
	methods: {
		getCurrentQuestionnaire() {
			return this.sessionBlock.questionnaires.find((questionnaire) =>
				questionnaire.tracks.some((t) => t._id === this.track._id)
			);
		},
		getFirstQuizBlock() {
			const sortedBlocks = [...this.session.sessionBlocks].sort((a, b) => a.position - b.position);
			return sortedBlocks.find((block) => block.type === 'quiz');
		},
		getNextQuizBlock(sessionBlockId) {
			const quizBlocks = [...this.session.sessionBlocks]
				.sort((a, b) => a.position - b.position)
				.filter((block) => block.type === 'quiz');

			const currentBlockIndex = quizBlocks.findIndex((block) => block._id === sessionBlockId);

			// currentBlockId not found or it's the last quiz block
			if (currentBlockIndex === -1 || currentBlockIndex === quizBlocks.length - 1) {
				return null;
			}

			// Return the next quiz block or null if not found
			return quizBlocks[currentBlockIndex + 1] || null;
		},
		getNextReviewBlock(sessionBlockId) {
			const reviewBlocks = [...this.session.sessionBlocks]
				.sort((a, b) => a.position - b.position)
				.filter((block) => block.type === 'review');

			const currentBlockIndex = reviewBlocks.findIndex((block) => block._id === sessionBlockId);

			// currentBlockId not found or it's the last review block
			if (currentBlockIndex === -1 || currentBlockIndex === reviewBlocks.length - 1) {
				return null;
			}

			// Return the next review block or null if not found
			return reviewBlocks[currentBlockIndex + 1] || null;
		},
		getFirstReviewBlock() {
			const sortedBlocks = [...this.session.sessionBlocks].sort((a, b) => a.position - b.position);
			return sortedBlocks.find((block) => block.type === 'review');
		},
		getNextQuestionnaire() {
			const questionnaire = this.getCurrentQuestionnaire();
			if (!questionnaire) return null;

			const currentIndex = this.sessionBlock.questionnaires.indexOf(questionnaire);
			return this.sessionBlock.questionnaires[currentIndex + 1] || null;
		},
		togglePromptAssistantDialog() {
			this.dialog = !this.dialog;
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('track-end-openExplanationSupport');
			}
		},
		redoTrack() {
			this.$router.go();
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('track-end-redo');
			}
		},
	},
	mounted() {},
};
</script>

<style lang="scss" scoped>
.track-final-buttons {
	gap: 12px;
	.redo-btn {
		min-width: 50px;
		padding-left: 10px;
		padding-right: 10px;
	}
	.next-btn {
		padding: 5px 20px;
		min-height: 37.5px;
		height: 100% !important;
	}
}

@media screen and (max-width: 600px) {
	.track-final-buttons a {
		width: 100%;
	}
}
</style>
