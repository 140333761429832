<template>
	<div class="robot-assistant rounded">
		<div class="assistant-container">
			<img src="@/assets/icons/assistant.svg" class="assistant-icon" />
			<span>Assistant</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'RobotAssistant',
};
</script>

<style lang="scss" scoped>
.robot-assistant {
	&:hover {
		background-color: #d7d4d4;
	}
	background-color: #e5e5e5;
	width: fit-content;
	padding: 5px 12px;
	.assistant-container {
		/* always write it like this for safari */
		display: flex;
		align-items: center;
		justify-content: space-around;
		span {
			font-size: 15px;
			font-weight: bold;
		}
	}
	.assistant-icon {
		width: 30px;
		height: 30px;
		margin-right: 10px;
	}
}
</style>
