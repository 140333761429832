<template>
	<div class="track-card rounded-lg" v-bind:class="[trackProps.bgClass]">
		<div class="heading-wrapper">
			<div class="d-flex align-center">
				<v-chip label small :color="trackProps.labelColor" text-color="white">{{ trackProps.label }} </v-chip>

				<div v-if="errors && languageToolApiFailed === false" class="counter" :style="{ background: counterColor }">
					{{ errors.length }}
				</div>

				<slot name="tooltip"></slot>
			</div>

			<slot name="headerText"></slot>
			<template v-if="trackProps.headerScore && !errors">
				<span :style="{ color: trackProps.labelColor }" class="header-score-text">
					<span class="font-weight-bold">
						{{ trackProps.headerScore }}
					</span>
					{{ trackProps.headerScoreText }}
				</span>
			</template>
		</div>
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'TrackCard',
	props: { trackProps: Object, errors: Array, languageToolApiFailed: Boolean },
	components: {},
	computed: {
		counterColor() {
			return this.errors.length === 0 ? 'var(--tag-green-primary)' : '#f95f62';
		},
	},
};
</script>

<style lang="scss" scoped>
.v-chip {
	font-size: 13px !important;
	width: fit-content;
	font-weight: bold;
}

.track-card {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 12px;

	.wrapper-content {
		display: flex;
		flex-direction: column;
	}

	.heading-wrapper {
		display: flex;
		gap: 24px;
		justify-content: space-between;
		align-items: center;

		.header-score-text {
			font-size: 12px;
		}

		.counter {
			color: white;
			border-radius: 50%;
			width: 20px;
			height: 20px;
			font-size: 14px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 4px;
		}
	}
}
</style>
