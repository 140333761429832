<template>
	<div class="tag-coefficient-container">
		<div class="tag-coefficient-triangle"></div>
		<span class="tag-name-coefficient">Coefficient {{ coefficient }}</span>
	</div>
</template>

<script>
export default {
	name: 'InfoCoefficient',
	props: {
		coefficient: Number,
	},
};
</script>

<style>
.tag-coefficient-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 30px;
	position: relative;
}

.tag-coefficient-triangle {
	border-radius: 50%;
	width: 8px;
	height: 29.7px;
	-webkit-clip-path: polygon(0 50%, 100% 100%, 100% 0);
	clip-path: polygon(0 50%, 100% 100%, 100% 0);
	background: black;
	border-right: 8px solid black;
	margin-right: -1.7px;
}

.tag-name-coefficient {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 24px;
	padding: 0px 10px 0px 15px;
	font-size: 13px;
	font-weight: 700;
	line-height: 1.5;
	color: white;
	background: black;
	border-radius: 0px 4px 4px 0px;
	border-right: 5px solid black;
}
</style>
