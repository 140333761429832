var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-7",class:[_vm.belowDesktop ? 'px-5' : 'px-13']},[_c('v-row',{staticClass:"track-qcm d-flex"},[_c('v-col',{staticClass:"left-block-wrapper",attrs:{"cols":12,"md":4}},[_c('div',{staticClass:"header-container"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex"},[(!_vm.belowDesktop)?_c('button',{staticClass:"mr-3",on:{"click":_vm.gotoSession}},[_c('v-icon',{attrs:{"size":"25px"}},[_vm._v("mdi-chevron-left")])],1):_vm._e(),_c('h2',[_vm._v(_vm._s(_vm.chooseAllAnswers === false ? _vm.getTitleText() : 'Résultats'))])])]),(this.sessionBlock.type === _vm.sessionBlockQuizType || this.sessionBlock.type == _vm.sessionBlockReviewType)?_c('div',[_c('InfoCoefficient',{attrs:{"coefficient":_vm.track.coefficient}})],1):_vm._e()]),(this.sessionBlock.type !== _vm.sessionBlockTrainingType)?_c('QuestionnaireScoreBlock',{attrs:{"userAnswers":_vm.userAnswers,"tracks":_vm.trackList,"track":_vm.track,"formationProgress":_vm.formationProgress}}):_vm._e(),(
					_vm.chooseAllAnswers === false &&
					_vm.track.tips &&
					_vm.track.tips.length > 0 &&
					this.sessionBlock.type === _vm.sessionBlockTrainingType
				)?_c('Tips',{attrs:{"tips":_vm.track.tips}}):_vm._e(),_c('QCMCountAnswers',{attrs:{"answers":_vm.track.answers,"userAnswers":_vm.userAnswers}}),(_vm.chooseAllAnswers === false && this.sessionBlock.type === _vm.sessionBlockQuizType)?_c('NoHelp'):_vm._e()],1),_c('v-col',{staticClass:"main-block-wrapper",attrs:{"cols":12,"md":8}},[_c('div',{staticClass:"d-flex"},[(_vm.belowDesktop)?_c('button',{staticClass:"mr-3",on:{"click":_vm.gotoSession}},[_c('v-icon',{attrs:{"size":"25px"}},[_vm._v("mdi-chevron-left")])],1):_vm._e(),_c('h2',[_vm._v(_vm._s(_vm.track.title))])]),_c('Instruction',{attrs:{"instruction":_vm.track.instruction}}),_c('div',[_c('SupportResources',{attrs:{"supportResources":_vm.track.support_resources}}),_c('div',{staticClass:"horizon-block my-5"},[_c('PromptAssistantDialog',{attrs:{"session":_vm.session,"dialog":_vm.dialog,"track":_vm.track,"promptType":_vm.promptAssistantType,"formationProgress":_vm.formationProgress},on:{"update:dialog":function($event){_vm.dialog=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var attrs = ref.attrs;
				var on = ref.on;
return [_c('button',_vm._g(_vm._b({on:{"click":_vm.togglePromptAssistantDialog}},'button',attrs,false),on),[_c('RobotAssistant',{class:{ 'd-none': _vm.chooseAllAnswers }})],1)]}}])}),_c('div',{staticClass:"horizon"})],1),_c('div',{staticClass:"explanation-container"},[_c('div',{staticClass:"question-block"},[_c('h3',[_vm._v(_vm._s(_vm.track.question))]),_vm._l((_vm.track.answers),function(answer){return _c('QCMAnswerItem',{key:answer._id,staticClass:"mt-5",attrs:{"answer":answer,"handleAnswer":_vm.handleAnswer,"userAnswers":_vm.userAnswers,"chooseAllAnswers":_vm.chooseAllAnswers}})})],2),(_vm.chooseAllAnswers && _vm.track.correction_explain_block)?_c('TrackExplanationCard',{attrs:{"track":_vm.track,"formationProgress":_vm.formationProgress,"withDelay":_vm.belowDesktop ? 2000 : 0,"userAnswers":_vm.userAnswers,"handleUpdateExplanationInParent":_vm.handleUpdateExplanationAI}}):_vm._e(),(_vm.chooseAllAnswers)?_c('TrackFinalButtons',{attrs:{"submitLoading":_vm.submitLoading,"track":_vm.track,"formationProgress":_vm.formationProgress,"session":_vm.session,"sessionBlock":_vm.sessionBlock,"previousExplanation":_vm.explanationAI}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }