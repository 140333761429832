<template>
	<div class="progress-bar-wrapper">
		<v-progress-linear
			:value="animatedProgress"
			height="10"
			:color="progressBarColor"
			rounded
			stream
			:background-color="progressBarBg"
		/>
		<div class="progress-values" :style="{ color: progressBarColor }">
			<span>{{ maxValue }}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ProgressBar',
	props: {
		value: {
			type: Number,
			required: true,
		},
		maxValue: {
			type: Number,
			required: true,
		},
		progressBarColor: {
			type: String,
			required: true,
		},
		progressBarBg: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			animatedProgress: 0,
		};
	},
	computed: {
		calculatedProgress() {
			return (this.value * 100) / this.maxValue;
		},
	},
	watch: {
		calculatedProgress: {
			handler(newProgress) {
				this.animateProgress(newProgress);
			},
			immediate: true,
		},
	},
	methods: {
		animateProgress(targetProgress) {
			const duration = 1000;
			const frameRate = 60;
			const totalFrames = (duration / 1000) * frameRate;
			const progressIncrement = targetProgress / totalFrames;
			let currentFrame = 0;

			const animate = () => {
				if (currentFrame < totalFrames) {
					this.animatedProgress += progressIncrement;
					currentFrame++;
					requestAnimationFrame(animate);
				} else {
					this.animatedProgress = targetProgress;
				}
			};

			this.animatedProgress = 0;
			animate();
		},
	},
};
</script>

<style lang="scss" scoped>
.progress-bar-wrapper {
	display: flex;
	align-items: center;

	.progress-values {
		padding-left: 12px;
		display: flex;
		justify-content: flex-end;
		font-size: 12px;
	}
}
</style>
