<template>
	<TrackCard :trackProps="trackProps">
		<div class="wrapper-content">
			<div v-for="(tip, index) in tips" :key="index" class="tip-item">
				<p v-html="tip"></p>
			</div></div
	></TrackCard>
</template>

<script>
export default {
	name: 'Tips',
	components: { TrackCard },
	props: { tips: Array },
	computed: {
		trackProps() {
			return {
				label: 'Astuces',
				labelColor: 'var(--tag-green-primary)',
				bgClass: 'bg-tag-green-secondary',
			};
		},
	},
};
import TrackCard from '@/components/track/track-card/TrackCard.vue';
</script>
<style lang="scss" scoped>
.tip-item {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	padding: 15px 0;
	line-height: 22px;

	p {
		margin: 0;
		font-size: 14px;
	}
}

.tip-item:not(:last-child) {
	border-bottom: 1px solid #000000de;
}
</style>
