export const removeHTMLTags = (str) => {
	return str.replace(/<\/?[^>]+(>|$)/g, '');
};

export const removeHTMLTagsAndEntities = (str) => {
	const cleanedStr = removeHTMLTags(str); // Remove HTML tags
	const div = document.createElement('div'); // Create a new div element

	div.innerHTML = cleanedStr; // Set the innerHTML of the div to the cleaned string
	return div.textContent || div.innerText || '';
};
