<template>
	<div class="block-assistant">
		<PromptAssistantDialog
			:session="session"
			:dialog.sync="dialog"
			:track="track"
			:promptType="promptAssistantType"
			:formationProgress="formationProgress"
		>
			<template v-slot:activator="{ attrs, on }">
				<button v-bind="attrs" v-on="on" @click="togglePromptAssistantDialog">
					<RobotAssistant />
				</button>
			</template>
		</PromptAssistantDialog>

		<div class="horizon" v-if="withLine"></div>
	</div>
</template>

<script>
import PromptAssistantDialog from '@/components/track/prompt-assistant-dialog/PromptAssistantDialog.vue';
import RobotAssistant from '@/components/robotAssistant/RobotAssistant.vue';
import { PROMPT_ASSISTANT_TYPE } from '../../constants/redaction';

export default {
	name: 'BlockAssistant',
	components: { PromptAssistantDialog, RobotAssistant },
	data() {
		return {
			promptAssistantType: PROMPT_ASSISTANT_TYPE,
			dialog: false,
		};
	},
	props: {
		session: Object,
		track: Object,
		formationProgress: Object,
		withLine: {
			default: false,
			type: Boolean,
		},
	},
	computed: {},
	methods: {
		togglePromptAssistantDialog() {
			this.dialog = !this.dialog;

			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('track-showAssistant');
			}
		},
	},
	mounted() {},
};
</script>

<style lang="scss" scoped>
.block-assistant {
	display: flex;
	align-items: center;
	gap: 10px;
	min-height: 40px;
	.horizon {
		height: 2px;
		width: 100%;
		background-color: #dedede;
	}
}
</style>
