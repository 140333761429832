<template>
	<div class="py-7" :class="[belowDesktop ? 'px-5' : 'px-13']">
		<v-row class="track-qcm d-flex">
			<v-col :cols="12" :md="4" class="left-block-wrapper">
				<div class="header-container">
					<div class="d-flex">
						<div class="d-flex">
							<button class="mr-3" @click="gotoSession" v-if="!belowDesktop">
								<v-icon size="25px">mdi-chevron-left</v-icon>
							</button>
							<h2>{{ chooseAllAnswers === false ? getTitleText() : 'Résultats' }}</h2>
						</div>
					</div>
					<div
						v-if="this.sessionBlock.type === sessionBlockQuizType || this.sessionBlock.type == sessionBlockReviewType"
					>
						<InfoCoefficient :coefficient="track.coefficient" />
					</div>
				</div>
				<QuestionnaireScoreBlock
					v-if="this.sessionBlock.type !== sessionBlockTrainingType"
					:userAnswers="userAnswers"
					:tracks="trackList"
					:track="track"
					:formationProgress="formationProgress"
				/>
				<Tips
					v-if="
						chooseAllAnswers === false &&
						track.tips &&
						track.tips.length > 0 &&
						this.sessionBlock.type === sessionBlockTrainingType
					"
					:tips="track.tips"
				/>
				<QCMCountAnswers :answers="track.answers" :userAnswers="userAnswers" />

				<NoHelp v-if="chooseAllAnswers === false && this.sessionBlock.type === sessionBlockQuizType" />
			</v-col>
			<v-col :cols="12" :md="8" class="main-block-wrapper">
				<div class="d-flex">
					<button class="mr-3" @click="gotoSession" v-if="belowDesktop">
						<v-icon size="25px">mdi-chevron-left</v-icon>
					</button>
					<h2>{{ track.title }}</h2>
				</div>

				<Instruction :instruction="track.instruction" />
				<div>
					<SupportResources :supportResources="track.support_resources" />
					<div class="horizon-block my-5">
						<PromptAssistantDialog
							:session="session"
							:dialog.sync="dialog"
							:track="track"
							:promptType="promptAssistantType"
							:formationProgress="formationProgress"
						>
							<template v-slot:activator="{ attrs, on }">
								<button v-bind="attrs" v-on="on" @click="togglePromptAssistantDialog">
									<RobotAssistant :class="{ 'd-none': chooseAllAnswers }" />
								</button>
							</template>
						</PromptAssistantDialog>

						<div class="horizon"></div>
					</div>
					<div class="explanation-container">
						<div class="question-block">
							<h3>{{ track.question }}</h3>

							<QCMAnswerItem
								v-for="answer in track.answers"
								:key="answer._id"
								:answer="answer"
								:handleAnswer="handleAnswer"
								:userAnswers="userAnswers"
								:chooseAllAnswers="chooseAllAnswers"
								class="mt-5"
							/>
						</div>
						<TrackExplanationCard
							v-if="chooseAllAnswers && track.correction_explain_block"
							:track="track"
							:formationProgress="formationProgress"
							:withDelay="belowDesktop ? 2000 : 0"
							:userAnswers="userAnswers"
							:handleUpdateExplanationInParent="handleUpdateExplanationAI"
						/>
						<TrackFinalButtons
							v-if="chooseAllAnswers"
							:submitLoading="submitLoading"
							:track="track"
							:formationProgress="formationProgress"
							:session="session"
							:sessionBlock="sessionBlock"
							:previousExplanation="explanationAI"
						/>
					</div>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import Tips from '@/components/track/expression/Tips.vue';
import SupportResources from '@/components/supportResources/SupportResources.vue';
import QCMCountAnswers from '@/components/track/qcm/QCMCountAnswers.vue';
import RobotAssistant from '@/components/robotAssistant/RobotAssistant.vue';
import NoHelp from '@/components/track/expression/NoHelp.vue';
import QCMAnswerItem from '@/components/track/qcm/QCMAnswerItem.vue';
import TrackExplanationCard from '@/components/track/track-explanation-card/TrackExplanationCard.vue';
import Instruction from '@/components/track/expression/Instruction.vue';
import TrackFinalButtons from '@/components/track/TrackFinalButtons.vue';
import PromptAssistantDialog from '@/components/track/prompt-assistant-dialog/PromptAssistantDialog.vue';
import QuestionnaireScoreBlock from '@/components/questionnaire/QuestionnaireScoreBlock.vue';
import InfoCoefficient from '@/components/tooltip/infoCoefficient.vue';

import {
	PROMPT_ASSISTANT_TYPE,
	SESSION_BLOCK_QUIZ_TYPE,
	SESSION_BLOCK_REVIEW_TYPE,
	SESSION_BLOCK_TRAINING_TYPE,
} from '../../../constants/redaction';

export default {
	name: 'TrackQCM',
	components: {
		Tips,
		QCMCountAnswers,
		NoHelp,
		SupportResources,
		RobotAssistant,
		QCMAnswerItem,
		TrackExplanationCard,
		Instruction,
		TrackFinalButtons,
		PromptAssistantDialog,
		QuestionnaireScoreBlock,
		InfoCoefficient,
	},
	props: { track: Object, formationProgress: Object, session: Object, sessionBlock: Object },
	data() {
		return {
			chooseAllAnswers: false, // Already answer by selected all possible answers
			userAnswers: [],
			dialog: false,
			submitLoading: false,
			promptAssistantType: PROMPT_ASSISTANT_TYPE,
			sessionBlockQuizType: SESSION_BLOCK_QUIZ_TYPE,
			sessionBlockTrainingType: SESSION_BLOCK_TRAINING_TYPE,
			sessionBlockReviewType: SESSION_BLOCK_REVIEW_TYPE,
			explanationAI: null, // TrackExplanationCard may call chatGPT to get explanation and update here to use in TrackFinalButtons later
		};
	},
	computed: {
		trackList() {
			const questionnaire = this.getCurrentQuestionnaire();
			return questionnaire.tracks;
		},
		maxPossibleAnswers() {
			return this.track.answers.filter((answer) => answer.is_good_answer).length;
		},
		belowDesktop() {
			return window.innerWidth <= 648;
		},
		sortedTrackList() {
			const questionnaire = this.getCurrentQuestionnaire();
			return questionnaire.tracks.sort((a, b) => a.position - b.position);
		},
		trackIndex() {
			return this.sortedTrackList.findIndex((t) => t._id === this.track._id);
		},
	},
	methods: {
		gotoSession() {
			const { formationId, sessionId } = this.$route.params;
			this.$router.push({
				name: 'SessionDetailPage',
				params: { formationId, sessionId },
			});
		},

		getTitleText() {
			const index = this.trackIndex + 1;

			switch (this.sessionBlock.type) {
				case this.sessionBlockTrainingType:
					return `Entraînement ${index}/${this.trackList.length}`;
				case this.sessionBlockQuizType:
					return `Quiz  ${index}/${this.trackList.length}`;
				case this.sessionBlockReviewType:
					return `Révision  ${index}/${this.trackList.length}`;

				default:
					return 'Aide';
			}
		},
		getCurrentQuestionnaire() {
			return this.sessionBlock.questionnaires.find((questionnaire) =>
				questionnaire.tracks.some((t) => t._id === this.track._id)
			);
		},
		togglePromptAssistantDialog() {
			this.dialog = !this.dialog;

			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('track-showAssistant');
			}
		},

		async handleAnswer(answer) {
			if (this.userAnswers.length === this.maxPossibleAnswers) return; // Already choose all answers this question

			this.userAnswers.push(answer);
			if (this.userAnswers.length === this.maxPossibleAnswers) this.chooseAllAnswers = true;

			const { formationId, sessionId, sessionBlockId } = this.$route.params;
			try {
				this.submitLoading = true;
				const response = await this.$store.dispatch('tracks/createTrackSubmitResponse', {
					formationId,
					sessionId,
					sessionBlockId,
					trackId: this.track._id,
					userAnswers: this.userAnswers,
				});

				if (response && response.data) {
					const newTrackResponse = response.data.trackResponse;
					await this.$store.dispatch('profile/updateTrackResponse', newTrackResponse);
				}

				this.submitLoading = false;
			} catch (error) {
				this.submitLoading = false;
			}
		},

		handleUpdateExplanationAI(explantion) {
			this.explanationAI = explantion;
		},
	},
	mounted() {
		window.scrollTo({ top: 0 });
	},
};
</script>

<style lang="scss" scoped>
.track-qcm {
	.header-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
	.main-block-wrapper {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.explanation-container {
			display: flex;
			flex-direction: column;
			gap: 24px;

			.question-block {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
	}
	.left-block-wrapper {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	.horizon-block {
		display: flex;
		align-items: center;
		gap: 10px;
		min-height: 40px;
		.horizon {
			height: 2px;
			width: 100%;
			background-color: #dedede;
		}
	}
}
@media only screen and (max-width: 900px) {
	.track-qcm {
		flex-direction: column-reverse;
	}
}
</style>
