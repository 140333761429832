<template>
	<div class="qcm">
		<TrackCard :trackProps="trackProps">
			<div class="progress-bar-wrapper" v-if="chooseAllAnswers">
				<v-progress-linear
					:value="progressBarValue"
					height="10"
					:color="progressBarColor"
					rounded
					stream
					:background-color="progressBarBg"
				></v-progress-linear>

				<span class="ml-2">{{ correctAnswers }}</span>
			</div>
		</TrackCard>
	</div>
</template>

<script>
import TrackCard from '@/components/track/track-card/TrackCard.vue';

export default {
	name: 'QCMCountAnswers',
	components: { TrackCard },
	props: { answers: Array, userAnswers: Array },

	computed: {
		numCorrectAnswer() {
			return this.answers.filter((answer) => answer.is_good_answer).length;
		},
		correctAnswers() {
			return this.userAnswers.filter((answer) => answer.is_good_answer).length;
		},
		chooseAllAnswers() {
			return this.numCorrectAnswer === this.userAnswers.length;
		},
		trackProps() {
			let headerScore = '';
			let headerScoreText = '';

			if (this.chooseAllAnswers) {
				headerScore = `${this.correctAnswers}/${this.numCorrectAnswer}`;
				headerScoreText = 'réponse trouvée';
			} else {
				headerScore = `${this.numCorrectAnswer} réponse${this.numCorrectAnswer > 1 ? 's' : ''}`;
				headerScoreText = 'à trouver ';
			}

			return {
				label: 'QCM',
				labelColor: 'var(--tag-purple-primary)',
				bgClass: 'bg-tag-purple-secondary',
				headerScore,
				headerScoreText,
			};
		},
		progressBarColor() {
			return 'var(--tag-purple-primary)';
		},
		progressBarBg() {
			return '#A4420021';
		},
		progressBarValue() {
			return (this.correctAnswers / this.numCorrectAnswer) * 100;
		},
	},
};
</script>

<style lang="scss" scoped>
.qcm {
	.progress-bar-wrapper {
		display: flex;
		align-items: center;
		flex-direction: row;
		span {
			color: var(--tag-purple-primary);
		}
	}
}
</style>
