<template>
	<div class="questionnaire-score-block">
		<TrackCard :trackProps="trackProps">
			<template v-slot:tooltip v-if="tooltip === 'expression'">
				<InfoTooltip position="right">
					<div>Calcul du Score :</div>
					<ul v-html="tooltipContent"></ul>
				</InfoTooltip>
			</template>
			<div class="track-wrapper">
				<TrackTitleAndScore
					v-for="(track, index) in sortTracksASC(tracks)"
					:isQuestionnaireFinish="trackWithScoresCount === tracks.length"
					:key="track._id"
					:track="track"
					:index="index"
					:userAnswers="userAnswers"
					:class="{ 'border-bottom': index !== tracks.length - 1 }"
				/>
			</div>
		</TrackCard>
	</div>
</template>

<script>
import TrackCard from '@/components/track/track-card/TrackCard.vue';
import InfoTooltip from '@/components/tooltip/InfoTooltip.vue';

import TrackTitleAndScore from './TrackTitleAndScore.vue';
import { getTrackResponseByTrackId } from '../../utils/redaction';

export default {
	name: 'QuestionnaireScoreBlock',
	components: { TrackTitleAndScore, InfoTooltip, TrackCard },
	props: {
		tracks: Array,
		track: Object,
		formationProgress: Object,
		userAnswers: Array,
		tooltip: {
			type: String,
			default: null,
		},
	},
	computed: {
		trackProps() {
			const { trackId, sessionId, sessionBlockId } = this.$route.params;

			// AdditionalScore is for QCM case where the score is manually updated
			const additionalScore =
				this.userAnswers.length > 0 &&
				!getTrackResponseByTrackId(this.formationProgress, sessionId, sessionBlockId, trackId)
					? 1
					: 0;

			const trackWithScoresCount = this.trackWithScoresCount + additionalScore;

			return {
				label: 'Score',
				labelColor: 'var(--tag-green-primary)',
				bgClass: 'bg-tag-green-secondary',
				headerScore: `${trackWithScoresCount} / ${this.tracks.length}`,
			};
		},
		trackWithScoresCount() {
			const { sessionId, sessionBlockId } = this.$route.params;

			const trackIdsWithScores = new Set(
				this.formationProgress.sessionScoresHasTrackResponses
					.flatMap((progress) => progress.trackResponses)
					.filter(
						(response) =>
							response.session_id === sessionId &&
							this.tracks.some((track) => track._id === response.track_id) &&
							response.session_block_id === sessionBlockId
					)
					.map((response) => response.track_id)
			);

			// Count tracks that have scores
			return this.tracks.filter((track) => trackIdsWithScores.has(track._id)).length;
		},
		tooltipContent() {
			let content = '';
			const { track_extension } = this.track;

			if (track_extension.keywords_expected) {
				content += '<li>Mots-clés : 8/10 = 80%</li>';
			}
			if (track_extension.max_answer_lenght) {
				content += '<li>Texte : 180/200 mots = 90%</li>';
			}

			if (track_extension.keywords_expected && track_extension.max_answer_lenght) {
				content += '<li>Score final : 85%</li>';
			} else if (track_extension.keywords_expected) {
				content += '<li>Score final : 80%</li>';
			} else if (track_extension.max_answer_lenght) {
				content += '<li>Score final : 90%</li>';
			}

			return content;
		},
	},
	methods: {
		sortTracksASC(tracks) {
			return [...tracks].sort((a, b) => a.position - b.position);
		},
	},
};
</script>

<style lang="scss" scoped>
.questionnaire-score-block {
	.track-wrapper {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
}
</style>
