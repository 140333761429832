<template>
	<v-dialog
		v-model="localDialog"
		:transition="belowDesktop ? 'dialog-bottom-transition' : 'dialog-left-right-transition'"
		:fullscreen="belowDesktop"
		@update:dialog="updateDialog"
		content-class="orthographe-errors-dialog"
	>
		<template v-slot:activator="{ props: activatorProps }">
			<slot name="activator" v-bind="activatorProps"></slot>
		</template>

		<v-card class="dialog-wrapper d-flex flex-column rounded-l-0" flat>
			<div class="dialog-header d-flex pa-3">
				<button @click="closeDialog" class="font-weight-bold">
					<v-icon class="mr-1">mdi-chevron-down</v-icon>
					Retourner à la correction
				</button>
			</div>
			<div class="pa-3 dialog-content">
				<div class="orthographe-items-wrapper">
					<div
						class="orthographe-item scroll-container d-flex rounded pa-2"
						v-for="(item, index) in items"
						:key="index"
					>
						<v-expansion-panels>
							<v-expansion-panel>
								<v-expansion-panel-header expand-icon="" class="pa-0">
									<template v-slot="{ open }">
										<template v-if="open">
											<h4 class="text-truncate">{{ item.type }}</h4>
										</template>
										<template v-else>
											<div class="d-flex justify-space-between align-center">
												<h4 class="text-truncate">{{ item.type }}</h4>
												<v-chip
													label
													small
													color="var(--tag-violet-primary)"
													v-if="item.suggestions.length > 0 && item.suggestions[0].value !== ' '"
													text-color="white"
													>{{ item.suggestions[0].value }}
												</v-chip>
											</div>
										</template>
									</template>
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<!-- Content Block -->
									<div class="content-block">
										<div class="suggestion-list">
											<!-- Suggestion Label -->
											<div v-for="(suggestion, sIndex) in item.suggestions" :key="sIndex">
												<v-chip
													v-if="suggestion.value !== ' '"
													label
													small
													color="var(--tag-violet-primary)"
													text-color="white"
													>{{ suggestion.value }}
												</v-chip>
											</div>
										</div>
										<div>
											<p class="ma-0">{{ item.explanation }}</p>
										</div>
									</div>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</div>
				</div>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'PromptAssistantDialog',
	components: {},
	props: {
		items: Array,
		dialog: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			localDialog: this.dialog,
			loading: false,
		};
	},
	computed: {
		belowDesktop() {
			return window.innerWidth <= 648;
		},
	},
	watch: {
		dialog(isOpen) {
			this.localDialog = isOpen;
		},
		localDialog(isOpen) {
			this.$emit('update:dialog', isOpen);
		},
	},
	methods: {
		closeDialog() {
			this.localDialog = false;
		},
		updateDialog(value) {
			this.localDialog = value;
		},
	},
	mounted() {},
};
</script>
<style lang="scss" scoped>
.dialog-wrapper {
	height: 100%;
	background-color: var(--tag-violet-secondary);

	.dialog-header {
		justify-content: center;
	}
	.dialog-content {
		flex: 1;
		overflow: hidden;
	}
}

.orthographe-items-wrapper {
	display: flex;
	flex-direction: column;
	gap: 12px;
	max-height: 100%;
	overflow: scroll;
	overflow-x: hidden;

	&::-webkit-scrollbar {
		width: 0px;
	}
	&::-webkit-scrollbar-track,
	&::-webkit-scrollbar-thumb,
	&::-webkit-scrollbar-thumb:hover {
		background: transparent;
	}

	.suggestion-list {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
	}
	.orthographe-item {
		gap: 10px;
		flex-direction: column;
		background-color: white;
		font-size: 12px;
		h4::before {
			content: '';
			display: inline-block;
			width: 8px;
			height: 8px;
			background-color: var(--tag-violet-primary);
			border-radius: 50%;
			margin-right: 5px;
		}

		h3,
		p {
			font-size: 14px;
		}

		.content-block {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}
	}
}

.v-expansion-panel-header {
	min-height: 30px;

	span {
		background-color: var(--tag-violet-primary);
	}
}

.v-expansion-panel-header--active {
	min-height: 40px;
}

::v-deep {
	.v-expansion-panel-content__wrap {
		padding: 0px;
	}
}
</style>
