<template>
	<v-tooltip
		:bottom="position === 'bottom'"
		:top="position === 'top'"
		:left="position === 'left'"
		:right="position === 'right'"
	>
		<template v-slot:activator="{ on, attrs }">
			<div class="info-tooltip">
				<img v-bind="attrs" v-on="on" src="@/assets/icons/info.png" />
			</div>
		</template>
		<slot></slot>
	</v-tooltip>
</template>

<script>
export default {
	name: 'InfoTooltip',
	props: {
		content: String,
		position: {
			type: String,
			default: 'top',
			validator: (value) => ['top', 'bottom', 'left', 'right'].includes(value),
		},
	},
};
</script>

<style lang="scss" scoped>
.info-tooltip {
	display: flex;
	align-items: center;

	img {
		margin-left: 4px;
		width: 20px;
	}
}
</style>
