<template>
	<div class="instruction">
		<TrackCard :trackProps="trackProps">
			<p v-html="instruction"></p>
		</TrackCard>
	</div>
</template>

<script>
export default {
	name: 'Instruction',
	components: { TrackCard },
	props: { instruction: String },
	computed: {
		trackProps() {
			return {
				label: 'Consignes',
				labelColor: 'var(--tag-blue-primary)',
				bgClass: 'bg-tag-blue-secondary',
			};
		},
	},
};
import TrackCard from '@/components/track/track-card/TrackCard.vue';
</script>

<style lang="scss" scoped>
.instruction {
	p {
		margin: 0;
	}
}
</style>
