<template>
	<div>
		<!-- Skeleton Loading -->
		<v-card
			id="loading-track-explanation"
			v-if="loading"
			class="track-explanation-card mx-auto bg-tag-green-secondary rounded-lg"
			flat
		>
			<div class="d-flex justify-space-between">
				<v-card-title> Les conseils de {{ track.correction_explain_block.full_name || 'Maria' }} 💡 </v-card-title>
				<v-card-actions>
					<v-icon @click="isCollapsed = !isCollapsed" size="25px" class="mr-1">{{
						isCollapsed ? 'mdi-chevron-up' : 'mdi-chevron-down'
					}}</v-icon>
				</v-card-actions>
			</div>
			<div class="profile-container px-4 pb-4">
				<div class="profile-avatar">
					<img :src="track.correction_explain_block.avatar_url" class="ortho-image-format-a" />
				</div>
				<div class="d-flex justify-center flex-column">
					<p class="ortho-bold">{{ track.correction_explain_block.full_name || 'Maria' }}</p>
					<p>{{ track.correction_explain_block.roll }}</p>
				</div>
			</div>
			<v-expand-transition>
				<div v-show="isCollapsed">
					<v-card-text>
						<div class="loading-dot-typing"></div>
						<p class="pt-2">{{ track.correction_explain_block.full_name || 'Maria' }} est en train d'écrire...</p>
					</v-card-text>
				</div>
			</v-expand-transition>
		</v-card>
		<!-- Skeleton Loading -->

		<v-card v-show="isVisible" class="track-explanation-card mx-auto bg-tag-green-secondary rounded-lg" flat>
			<!-- Header Block -->
			<div class="d-flex justify-space-between">
				<v-card-title> Les conseils de {{ track.correction_explain_block.full_name || 'Maria' }} 💡 </v-card-title>
				<v-card-actions>
					<v-icon @click="isCollapsed = !isCollapsed" size="25px" class="mr-1">{{
						isCollapsed ? 'mdi-chevron-up' : 'mdi-chevron-down'
					}}</v-icon>
				</v-card-actions>
			</div>

			<!-- Profile Block -->
			<div class="profile-container px-4 pb-4">
				<div class="profile-avatar">
					<img :src="track.correction_explain_block.avatar_url" class="ortho-image-format-a" />
				</div>
				<div class="d-flex justify-center flex-column">
					<p class="ortho-bold">{{ track.correction_explain_block.full_name || 'Maria' }}</p>
					<p>{{ track.correction_explain_block.roll }}</p>
				</div>
			</div>

			<!-- Content Block -->
			<v-expand-transition>
				<div v-show="isCollapsed">
					<!-- Typing explanation block -->
					<v-card-text>
						<div id="typing-explanation"></div>
					</v-card-text>
				</div>
			</v-expand-transition>
		</v-card>
	</div>
</template>

<script>
import Axios from 'axios';
import { renderTextTypingEffect, renderTextWithoutTypingEffect } from '@/utils/typing-effect';
import { parseTemplate } from '@/utils/text.js';
import { removeHTMLTagsAndEntities } from '@/utils/html.js';

export default {
	name: 'TrackExplanationCard',
	props: {
		track: Object,
		formationProgress: Object,
		withDelay: {
			type: Number,
			default: 0,
		},
		userAnswers: Array,
		handleUpdateExplanationInParent: Function,
	},
	data() {
		return {
			startY: 0,
			intervalID: null,
			isCollapsed: true,
			hasScrollUp: false,
			isVisible: false,
			explanation: '',
			axiosSource: Axios.CancelToken.source(),
			loading: this.track.correction_explain_block.use_prompt,
		};
	},
	computed: {
		belowDesktop() {
			return window.innerWidth <= 648;
		},
	},
	methods: {
		generateDataForQCMPromptTemplate(user, track, userAnswers) {
			let strAllTrackAnswers = '';
			let strAllTrackGoodAnswers = '';
			let strAllUserAnswers = '';
			let strAllResourcePromptText = '';

			track.answers.forEach((answer) => {
				strAllTrackAnswers = strAllTrackAnswers + `${strAllTrackAnswers !== '' ? ', ' : ''}${answer.answer}`;
				if (answer.is_good_answer === true)
					strAllTrackGoodAnswers =
						strAllTrackGoodAnswers + `${strAllTrackGoodAnswers !== '' ? ', ' : ''}${answer.answer}`;
			});

			userAnswers.forEach((answer) => {
				strAllUserAnswers = strAllUserAnswers + `${strAllUserAnswers !== '' ? ', ' : ''}${answer.answer}`;
			});

			const dataQCM = {
				firstName: user.first_name,
				gender: user.gender,
				trackInstruction: removeHTMLTagsAndEntities(track.instruction),
				trackQuestion: track.question,
				trackAnswers: strAllTrackAnswers,
				trackGoodAnswer: strAllTrackGoodAnswers,
				userResponse: strAllUserAnswers,
			};

			if (track.support_resources && track.support_resources.length > 0) {
				track.support_resources
					.sort((a, b) => a.position - b.position)
					.forEach((resource) => {
						if (['text', 'audio'].includes(resource.type)) {
							strAllResourcePromptText =
								strAllResourcePromptText + `${strAllResourcePromptText !== '' ? ', ' : ''}${resource.prompt_text}`;
						}
					});
			}

			if (strAllResourcePromptText !== '') dataQCM.trackResourcesText = strAllResourcePromptText;

			return dataQCM;
		},
		generateDataForExpressionPromptTemplate(user, track, userAnswers) {
			let strAllResourcePromptText = '';

			const dataExpression = {
				firstName: user.first_name,
				gender: user.gender,
				trackInstruction: removeHTMLTagsAndEntities(track.instruction),
				userResponse: userAnswers[0],
			};

			if (track.support_resources && track.support_resources.length > 0) {
				track.support_resources
					.sort((a, b) => a.position - b.position)
					.forEach((resource) => {
						if (['text', 'audio'].includes(resource.type)) {
							strAllResourcePromptText =
								strAllResourcePromptText + `${strAllResourcePromptText !== '' ? ', ' : ''}${resource.prompt_text}`;
						}
					});
			}

			if (strAllResourcePromptText !== '') dataExpression.trackResourcesText = strAllResourcePromptText;
			if (track.question) dataExpression.trackQuestion = track.question;
			return dataExpression;
		},
		async getExplanationAI(promptTemplate, user, track, userAnswers) {
			const timeoutDuration = 8000; // 8 seconds
			let data = null;

			if (track.question_type === 'QCM') data = this.generateDataForQCMPromptTemplate(user, track, userAnswers);
			else if (track.question_type === 'Expression')
				data = this.generateDataForExpressionPromptTemplate(user, track, userAnswers);

			const question = parseTemplate(promptTemplate, data);

			if (!this.belowDesktop) {
				const ele = document.querySelector('#loading-track-explanation');
				if (ele) ele.scrollIntoView({ behavior: 'smooth' }); // Scroll to loading skeleton
			}

			const timeoutPromise = new Promise((_, reject) =>
				setTimeout(() => reject(new Error('Request timed out')), timeoutDuration)
			);

			try {
				let answer = null;
				await Promise.race([
					this.$store.dispatch('exercice/getQuestionExplanationFromAI', {
						question,
						config: { cancelToken: this.axiosSource.token },
					}),
					timeoutPromise,
				])
					.then((data) => {
						this.loading = false;
						answer = data;
					})
					.catch(() => {
						// When call chat GPT take too long or request failed return static explanation
						this.axiosSource.cancel();
						answer = track.correction_explain_block.explanation;
						this.loading = false;
					});
				return answer;
			} catch (error) {
				console.error('Fetch error:', error);
			}
		},
		async getExplantion(trackExplanationObj) {
			try {
				if (trackExplanationObj.use_prompt === true) {
					this.loading = true;

					this.explanation = await this.getExplanationAI(
						trackExplanationObj.prompt_template,
						this.formationProgress.user,
						this.track,
						this.userAnswers
					);
				} else this.explanation = trackExplanationObj.explanation;
				return this.explanation;
			} catch (error) {
				return trackExplanationObj.explanation;
			}
		},
		async renderExplanation(explanation) {
			if (explanation) {
				if (!this.belowDesktop) {
					this.scrollToBottomDialogBody();
					if (this.track.correction_explain_block.use_prompt !== true) await this.delay(1500);
				}

				this.hasScrollUp = false;

				this.intervalID = renderTextTypingEffect(
					explanation,
					'typing-explanation',
					!this.belowDesktop ? this.scrollToBottomDialogBody : null,
					null,
					null
				);
			}
		},

		delay(ms) {
			return new Promise((resolve) => setTimeout(resolve, ms));
		},

		scrollToBottomDialogBody() {
			const vCardTextElement = document.querySelector('html');

			if (vCardTextElement) {
				vCardTextElement.scroll({
					top: vCardTextElement.scrollHeight,
					behavior: 'smooth',
				});
			}
		},

		handleScroll() {
			if (this.hasScrollUp) return;
			const scrollY = window.scrollY;

			// If a scroll up is detected, the explanation will be displayed without the typing effect.
			if (scrollY < this.startY && this.intervalID) {
				clearInterval(this.intervalID);
				renderTextWithoutTypingEffect(this.explanation, 'typing-explanation');
				this.hasScrollUp = true;
			}

			this.startY = scrollY;
		},
	},

	async mounted() {
		const explanation = await this.getExplantion(this.track.correction_explain_block);
		this.handleUpdateExplanationInParent(explanation);
		this.renderExplanation(explanation);
		window.addEventListener('scroll', this.handleScroll);
		setTimeout(() => {
			this.isVisible = true;
		}, this.withDelay);
	},

	unmounted() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	destroyed() {
		clearInterval(this.intervalID);
		this.axiosSource.cancel();
	},
};
</script>

<style lang="scss" scoped>
.track-explanation-card {
	width: 100%;
	padding: 0;

	.v-card__title {
		font-size: 18px;
		font-weight: 700;
	}

	.v-card__text {
		font-size: 16px;
	}

	.profile-container {
		display: flex;
		gap: 16px;

		p {
			margin: 0;
			font-size: 12px;
		}
		.profile-avatar {
			overflow: hidden;
			width: 40px;
			height: 40px;
			background-color: white;
			border-radius: 50%;

			img {
				width: 100%;
			}
		}
	}
}
</style>
