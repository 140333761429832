<template>
	<v-container class="px-0 pt-0 pb-13">
		<Loading :show="loading" label="3, 2, 1..." />
		<TrackComponent
			v-if="track && formationProgress && session && !loading"
			:track="track"
			:formationProgress="formationProgress"
			:session="session"
		/>
	</v-container>
</template>

<script>
import Loading from 'vue-full-loading';
import TrackComponent from '@/components/track/TrackComponent.vue';
import { mapGetters } from 'vuex';

export default {
	name: 'TrackPage',
	components: { TrackComponent, Loading },
	data() {
		return {
			loading: false,
			track: null,
			session: null,
		};
	},
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
	},
	methods: {
		async fetchTrack(trackId) {
			if (!trackId) return;

			this.track = await this.$store.dispatch('tracks/getTrackDetail', { trackId });
		},
		async fetchSession(sessionId) {
			if (!sessionId) return;

			this.session = await this.$store.dispatch('sessions/getSessionDetail', {
				sessionId,
			});
		},
		async fetchFormationProgress(formationId) {
			if (!this.formationProgress || this.formationProgress.formation._id !== formationId) {
				const formationProgress = await this.$store.dispatch('profile/formationProgress', {
					idFormation: formationId,
				});
				if (formationProgress.error) return;
			}
		},
	},
	async mounted() {
		const { sessionId, formationId, trackId } = this.$route.params;
		this.loading = true;
		await this.fetchSession(sessionId);
		await this.fetchFormationProgress(formationId);
		await this.fetchTrack(trackId);
		this.loading = false;
	},
	watch: {
		async '$route.params.trackId'(newTrackId, oldTrackId) {
			if (newTrackId === oldTrackId) {
				return;
			} else {
				this.loading = true;
				await this.fetchTrack(newTrackId);
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@media (min-width: 960px) {
	.container {
		max-width: 1185px;
	}
}
</style>
