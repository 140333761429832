/**
 * Function to rerieve a track response exists for a given session, session block, and track.
 *
 * @param {Object} formationProgress - The formation progress object
 * @param {string} sessionId - The ID of the session.
 * @param {string} sessionBlockId - The ID of the session block.
 * @param {string} trackId - The ID of the track.
 * @returns {object|null} - Returns the track response object if found, otherwise null.
 */

export function getTrackResponseByTrackId(formationProgress, sessionId, sessionBlockId, trackId) {
	for (let progress of formationProgress.sessionScoresHasTrackResponses) {
		const response = progress.trackResponses.find((response) => {
			return (
				response.session_block_id === sessionBlockId &&
				response.track_id === trackId &&
				response.session_id === sessionId
			);
		});

		if (response) {
			return response;
		}
	}

	return null;
}
