<template>
	<div>
		<TrackExpression
			v-if="track.question_type === 'Expression'"
			:track="track"
			:formationProgress="formationProgress"
			:session="session"
			:sessionBlock="sessionBlock"
		/>
		<TrackQCM
			v-else-if="track.question_type === 'QCM'"
			:track="track"
			:formationProgress="formationProgress"
			:session="session"
			:sessionBlock="sessionBlock"
		/>
	</div>
</template>

<script>
import TrackExpression from '@/components/track/expression/TrackExpression.vue';
import TrackQCM from '@/components/track/qcm/TrackQCM.vue';

export default {
	name: 'TrackComponent',
	components: { TrackExpression, TrackQCM },
	props: { track: Object, formationProgress: Object, session: Object },
	computed: {
		sessionBlock() {
			const { sessionBlockId } = this.$route.params;

			if (!sessionBlockId) return;

			const sessionBlock = this.session.sessionBlocks.find((block) => block._id === sessionBlockId);

			return sessionBlock || null;
		},
	},
};
</script>

<style lang="scss" scoped></style>
