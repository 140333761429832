<template>
	<div class="no-help rounded">
		<img src="@/assets/images/binoculars.png" />
		<p class="ortho-bold">Pas d’aide, c’est le quiz !</p>
	</div>
</template>

<script>
export default {
	name: 'NoHelp',
};
</script>

<style lang="scss" scoped>
.no-help {
	height: 188px;
	background: #f3f3f3;
	display: flex;
	flex-direction: column;
	color: #a1a1a1;
	font-size: 12px;
	line-height: 22px;
	gap: 6px;

	align-items: center;
	justify-content: center;
	img {
		width: 60px;
	}
}
</style>
