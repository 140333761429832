<template>
	<TrackCard :trackProps="trackProps">
		<ProgressBar
			v-if="isAnswering"
			progressBarColor="var(--tag-orange-primary)"
			progressBarBg="#A4420021"
			:value="totalLength"
			:maxValue="track.track_extension.max_answer_lenght"
		/>
	</TrackCard>
</template>

<script>
import TrackCard from '@/components/track/track-card/TrackCard.vue';
import ProgressBar from '@/components/progress-bar/ProgressBar.vue';
import { countWord } from '@/utils/text.js';

export default {
	name: 'TextLength',
	components: { TrackCard, ProgressBar },
	data() {
		return {
			isAnswering: false,
			totalLength: 0,
		};
	},
	props: { track: Object, userAnswers: Array },
	computed: {
		trackProps() {
			if (this.track.track_extension == null) return '';
			return {
				label: 'Longueur de texte',
				labelColor: 'var(--tag-orange-primary)',
				bgClass: 'bg-tag-orange-secondary',
				headerScore: !this.isAnswering
					? this.track.track_extension.max_answer_lenght
					: `${this.totalLength} / ${this.track.track_extension.max_answer_lenght}`,
				headerScoreText: !this.isAnswering ? 'mots à écrire' : '',
			};
		},
	},

	watch: {
		userAnswers: {
			handler(newAnswers) {
				const countNewAnswer = countWord(newAnswers[0]);
				this.isAnswering = newAnswers.length > 0;
				if (this.isAnswering) {
					this.totalLength =
						countNewAnswer >= this.track.track_extension.max_answer_lenght
							? this.track.track_extension.max_answer_lenght
							: countNewAnswer;
				}
			},
			immediate: true,
		},
	},
};
</script>

<style lang="scss" scoped></style>
