<template>
	<div
		class="qcm-answer-item"
		:class="[computedClassnames, { 'cursor-pointer': !chooseAllAnswers }]"
		@click="selectAnswer"
	>
		{{ answer.answer }}
	</div>
</template>

<script>
export default {
	name: 'QCMAnswerItem',
	props: { answer: Object, chooseAllAnswers: Boolean, handleAnswer: Function, userAnswers: Array },
	computed: {
		decideAnswerClassname() {
			if (this.chooseAllAnswers === false) return '';
			if (this.answer.is_good_answer) return 'correct';

			const isInUserAnswers = this.userAnswers.find((userAnswer) => userAnswer._id === this.answer._id);
			if (isInUserAnswers != null) return 'wrong';
			return '';
		},
		computedClassnames() {
			const baseClassnames = {
				correct: this.decideAnswerClassname === 'correct',
				wrong: this.decideAnswerClassname === 'wrong',
			};

			if (
				this.userAnswers &&
				this.userAnswers.length > 0 &&
				this.userAnswers[0].is_good_answer &&
				this.decideAnswerClassname === 'correct'
			) {
				return {
					...baseClassnames,
					'bg-tag-green-primary': true,
					'white--text': true,
				};
			}

			return baseClassnames;
		},
	},
	methods: {
		selectAnswer() {
			this.handleAnswer(this.answer);
		},
	},
};
</script>

<style lang="scss" scoped>
.qcm-answer-item {
	border: 2px solid #ddddde;
	border-radius: 8px;
	width: 300px;
	text-align: center;
	padding: 10px;
}

.qcm-answer-item.correct {
	border: 2px solid #15c39a;
	color: black;
}

.qcm-answer-item.wrong {
	background-color: #ff6666;
	border-color: #ff6666;
	color: white;
}

.bg-tag-green-primary {
	background-color: #15c39a;
}

.white--text {
	color: white;
}
</style>
