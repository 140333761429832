<template>
	<div class="py-7" :class="[belowDesktop ? 'px-5' : 'px-13']">
		<v-row class="track-exppression d-flex">
			<v-col :cols="12" :md="4" class="left-block-wrapper">
				<div class="header-container">
					<div class="d-flex">
						<div class="d-flex">
							<button class="mr-3" @click="gotoSession" v-if="!belowDesktop">
								<v-icon size="25px">mdi-chevron-left</v-icon>
							</button>
							<h2>{{ isSubmit ? 'Résultats' : getTitleText() }}</h2>
						</div>
					</div>
					<div
						v-if="this.sessionBlock.type === sessionBlockQuizType || this.sessionBlock.type == sessionBlockReviewType"
					>
						<InfoCoefficient :coefficient="track.coefficient" />
					</div>
				</div>
				<QuestionnaireScoreBlock
					v-if="this.sessionBlock.type !== sessionBlockTrainingType"
					tooltip="expression"
					:userAnswers="userAnswers"
					:tracks="trackList"
					:track="track"
					:formationProgress="formationProgress"
				/>

				<Tips
					v-if="track.tips && track.tips.length > 0 && this.sessionBlock.type === sessionBlockTrainingType && !isSubmit"
					:tips="track.tips"
				/>
				<TextLength v-if="track.track_extension.max_answer_lenght" :track="track" :userAnswers="userAnswers" />
				<Keyword
					v-if="track.track_extension.keywords_expected"
					:track="track"
					:userAnswers="userAnswers"
					:sessionBlock="sessionBlock"
				/>
				<Orthographe
					:errors="correctionErrors"
					@onErrorSelect="handleErrorSelect"
					:languageToolApiFailed="languageToolFailed"
				/>
				<NoHelp v-if="!isSubmit && this.sessionBlock.type !== sessionBlockTrainingType" />
				<TrackFinalButtons
					v-if="isSubmit && belowDesktop"
					:track="track"
					:formationProgress="formationProgress"
					:session="session"
					:sessionBlock="sessionBlock"
					:previousExplanation="explanationAI"
				/>
			</v-col>
			<v-col :cols="12" :md="8" class="main-block-wrapper">
				<div class="d-flex">
					<button class="mr-3" @click="gotoSession" v-if="belowDesktop">
						<v-icon size="25px">mdi-chevron-left</v-icon>
					</button>
					<h2>{{ this.track.title }}</h2>
				</div>
				<Instruction :instruction="track.instruction" />
				<div>
					<SupportResources :supportResources="track.support_resources" />
					<div class="mt-4" v-if="belowDesktop && !isSubmit">
						<BlockAssistant :track="track" :formationProgress="formationProgress" :session="session" withLine />
					</div>
				</div>

				<ExpressionAnswerForm
					@languageToolErrors="handleLanguageTotalErrorChange"
					@onAnswerSubmit="handleExpressionAnswerSubmit"
					@languageToolAPIFailed="handleLanguageToolApiFail"
					:session="session"
					:track="track"
					:highlightedError="highlightedError"
					:formationProgress="formationProgress"
				/>
				<TrackExplanationCard
					v-if="isSubmit && track.correction_explain_block"
					:track="track"
					:formationProgress="formationProgress"
					:withDelay="belowDesktop ? 2000 : 0"
					:userAnswers="userAnswers"
					:handleUpdateExplanationInParent="handleUpdateExplanationAI"
				/>
				<TrackFinalButtons
					v-if="isSubmit && !belowDesktop"
					:track="track"
					:formationProgress="formationProgress"
					:session="session"
					:sessionBlock="sessionBlock"
					:previousExplanation="explanationAI"
				/>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import Tips from '@/components/track/expression/Tips.vue';
import Instruction from '@/components/track/expression/Instruction.vue';
import TextLength from '@/components/track/expression/TextLength.vue';
import Keyword from '@/components/track/expression/Keyword.vue';
import Orthographe from '@/components/track/expression/Orthographe.vue';
import SupportResources from '@/components/supportResources/SupportResources.vue';
import TrackFinalButtons from '@/components/track/TrackFinalButtons.vue';
import QuestionnaireScoreBlock from '../../questionnaire/QuestionnaireScoreBlock.vue';
import ExpressionAnswerForm from './ExpressionAnswerForm.vue';
import TrackExplanationCard from '@/components/track/track-explanation-card/TrackExplanationCard.vue';
import NoHelp from '@/components/track/expression/NoHelp.vue';
import InfoCoefficient from '@/components/tooltip/infoCoefficient.vue';
import {
	SESSION_BLOCK_QUIZ_TYPE,
	SESSION_BLOCK_REVIEW_TYPE,
	SESSION_BLOCK_TRAINING_TYPE,
} from '../../../constants/redaction';
import BlockAssistant from '../../block/BlockAssistant.vue';

export default {
	name: 'TrackExpression',
	components: {
		NoHelp,
		BlockAssistant,
		Instruction,
		Tips,
		TextLength,
		Keyword,
		Orthographe,
		SupportResources,
		TrackFinalButtons,
		QuestionnaireScoreBlock,
		ExpressionAnswerForm,
		TrackExplanationCard,
		InfoCoefficient,
	},
	data() {
		return {
			userAnswers: [],
			correctionErrors: null,
			highlightedError: null,
			isSubmit: false,
			sessionBlockTrainingType: SESSION_BLOCK_TRAINING_TYPE,
			sessionBlockQuizType: SESSION_BLOCK_QUIZ_TYPE,
			sessionBlockReviewType: SESSION_BLOCK_REVIEW_TYPE,
			isLoading: false,
			languageToolFailed: false,
			explanationAI: null, // TrackExplanationCard may call chatGPT to get explanation and update here to use in TrackFinalButtons later
		};
	},
	props: { track: Object, formationProgress: Object, session: Object, sessionBlock: Object },
	computed: {
		belowDesktop() {
			return window.innerWidth <= 648;
		},
		trackList() {
			const questionnaire = this.getCurrentQuestionnaire();
			return questionnaire.tracks;
		},
		sortedTrackList() {
			const questionnaire = this.getCurrentQuestionnaire();
			return questionnaire.tracks.sort((a, b) => a.position - b.position);
		},
		trackIndex() {
			return this.sortedTrackList.findIndex((t) => t._id === this.track._id);
		},
	},
	methods: {
		getTitleText() {
			const index = this.trackIndex + 1;

			switch (this.sessionBlock.type) {
				case this.sessionBlockTrainingType:
					return `Entraînement ${index}/${this.trackList.length}`;
				case this.sessionBlockQuizType:
					return `Quiz  ${index}/${this.trackList.length}`;
				case this.sessionBlockReviewType:
					return `Révision  ${index}/${this.trackList.length}`;
				default:
					return 'Aide';
			}
		},
		gotoSession() {
			const { formationId, sessionId } = this.$route.params;
			this.$router.push({
				name: 'SessionDetailPage',
				params: { formationId, sessionId },
			});
		},
		getCurrentQuestionnaire() {
			return this.sessionBlock.questionnaires.find((questionnaire) =>
				questionnaire.tracks.some((t) => t._id === this.track._id)
			);
		},

		handleLanguageTotalErrorChange(errors) {
			this.correctionErrors = errors;
		},

		handleExpressionAnswerSubmit(answer) {
			this.isSubmit = true;
			this.userAnswers = [answer];
		},
		handleErrorSelect(error) {
			this.highlightedError = error;
		},
		handleLanguageToolApiFail() {
			this.languageToolFailed = true;
		},
		handleUpdateExplanationAI(explantion) {
			this.explanationAI = explantion;
		},
	},
	mounted() {
		window.scrollTo({ top: 0 });
	},
};
</script>

<style lang="scss" scoped>
.header-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.main-block-wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.left-block-wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

@media only screen and (max-width: 900px) {
	.track-exppression {
		flex-direction: column-reverse;
	}
}
</style>
