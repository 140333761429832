<template>
	<div class="track-title-score">
		<div class="track-item d-flex justify-space-between align-center w-100">
			<router-link
				v-if="isQuestionnaireFinish"
				:class="['black--text text-body-2', { 'font-weight-bold': isHighlightingItem }]"
				:to="trackRouteParams"
			>
				{{ `Q${index + 1} : ${track.title}` }}
			</router-link>
			<span v-else :class="['black--text text-body-2', { 'font-weight-bold': isHighlightingItem }]">
				{{ `Q${index + 1} : ${track.title}` }}
			</span>
			<span
				v-if="!isHighlightingItem && trackWithScore"
				:class="{ passed: score >= 80, 'not-passed': score < 80 }"
				class="font-weight-bold"
				>{{ score }}%</span
			>
		</div>
		<div class="progress-bar-wrapper" v-if="isHighlightingItem && score !== null">
			<v-progress-linear
				:value="score"
				height="10"
				color="var(--tag-green-primary)"
				rounded
				stream
				background-color="#C5F0E6"
			/>
			<div class="progress-values">
				<span :class="{ passed: score >= 80, 'not-passed': score < 80 }" class="font-weight-bold">{{ score }}%</span>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getTrackResponseByTrackId } from '../../utils/redaction';
export default {
	name: 'TrackTitleAndScore',
	props: { track: Object, index: Number, userAnswers: Array, isQuestionnaireFinish: Boolean },
	data() {
		return {
			score: null,
		};
	},
	computed: {
		...mapGetters('profile', {
			formationProgress: 'formationProgress',
		}),
		isHighlightingItem() {
			const { trackId } = this.$route.params;
			return this.track._id === trackId;
		},
		trackWithScore() {
			const { sessionId, sessionBlockId } = this.$route.params;
			return getTrackResponseByTrackId(this.formationProgress, sessionId, sessionBlockId, this.track._id);
		},
		trackRouteParams() {
			const { formationId, sessionId, sessionBlockId } = this.$route.params;
			return {
				name: 'TrackPage',
				params: {
					formationId,
					sessionId,
					sessionBlockId,
					trackId: this.track._id,
				},
			};
		},
	},
	watch: {
		userAnswers: {
			handler(newAnswers) {
				if (this.isHighlightingItem) {
					this.track.question_type === 'QCM' ? this.updateQCMScore(newAnswers) : this.updateExpressionScore();
				}
			},
		},
	},
	methods: {
		updateQCMScore(newAnswers) {
			const goodAnswerCount = newAnswers.filter((answer) => answer.is_good_answer).length;

			if (goodAnswerCount > 0) {
				this.score = 100;
			} else {
				this.score = 0;
			}
		},
		updateExpressionScore() {
			this.score = this.trackWithScore.score;
		},
	},
	mounted() {
		this.score = this.trackWithScore ? this.trackWithScore.score : null;
	},
};
</script>
<style lang="scss" scoped>
.track-title-score {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	padding: 15px 0;
	line-height: 22px;
	gap: 12px;
	font-size: 12px;
	.track-item {
		gap: 8px;
	}
	.progress-bar-wrapper {
		width: 100%;
		display: flex;
		align-items: center;
		gap: 8px;
	}
	.passed {
		color: var(--tag-green-primary);
	}
	.not-passed {
		color: #a0a0a0;
	}
}
.track-title-score:not(:last-child) {
	border-bottom: 1px solid #cee3df;
}
</style>
