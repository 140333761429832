<template>
	<div class="orthographe">
		<TrackCard :trackProps="trackProps" :errors="errors" :languageToolApiFailed="languageToolApiFailed">
			<template v-slot:headerText v-if="belowDesktop && errors && errors.length > 0">
				<OrthographeErrorsDialog :dialog.sync="dialog" :items="items">
					<template v-slot:activator="{ attrs, on }">
						<button v-bind="attrs" v-on="on" @click="toggleOrthographeErrorsDialog" class="d-flex">
							<div>Voir mes fautes</div>
							<v-icon size="25px">{{ !dialog ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
						</button>
					</template>
				</OrthographeErrorsDialog>
			</template>
			<div class="orthographe-items-wrapper" v-show="!belowDesktop && errors">
				<div class="orthographe-item scroll-container d-flex rounded pa-2" v-for="(item, index) in items" :key="index">
					<div @click="handleSelectItem(item)">
						<v-expansion-panels>
							<v-expansion-panel>
								<v-expansion-panel-header expand-icon="" class="pa-0">
									<template v-slot="{ open }" ref="targetButton">
										<div ref="targetButton">
											<template v-if="open">
												<h4 class="text-truncate">{{ item.type }}</h4>
											</template>
											<template v-else>
												<div class="d-flex justify-space-between align-center">
													<h4 class="text-truncate">{{ item.type }}</h4>
													<v-chip
														v-if="item.suggestions.length > 0 && item.suggestions[0].value !== ' '"
														label
														small
														:color="trackProps.labelColor"
														text-color="white"
														>{{ item.suggestions[0].value }}
													</v-chip>
												</div>
											</template>
										</div>
									</template>
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<!-- Content Block -->
									<div
										class="content-block"
										@touchstart="handlecontentClick(index)"
										@touchend="handlecontentClick(index)"
										@click="handlecontentClick(index)"
									>
										<div class="suggestion-list">
											<!-- Suggestion Label -->
											<div v-for="(suggestion, sIndex) in item.suggestions" :key="sIndex">
												<v-chip
													label
													small
													:color="trackProps.labelColor"
													text-color="white"
													v-if="suggestion.value !== ' '"
													>{{ suggestion.value }}
												</v-chip>
											</div>
										</div>
										<div>
											<p class="ma-0">{{ item.explanation }}</p>
										</div>
									</div>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</div>
				</div>
			</div>

			<div v-if="languageToolApiFailed">
				<div class="d-flex">
					<p class="ma-0">Correction non prise en compte. 🥲</p>
				</div>
			</div>
			<div v-else-if="errors && errors.length === 0">
				<div class="d-flex">
					<p class="ma-0">Aucune faute détectée. Excellent travail ! 😊👍</p>
				</div>
			</div>
		</TrackCard>
	</div>
</template>

<script>
import TrackCard from '@/components/track/track-card/TrackCard.vue';
import OrthographeErrorsDialog from '../orthographe-errors-dialog/OrthographeErrorsDialog.vue';
import { CATEGORY_MAPPING, CATEGORIES_DONT_WANT_TO_SHOW } from '@/constants/languageTool.js';

export default {
	name: 'Orthographe',
	components: { TrackCard, OrthographeErrorsDialog },
	props: { errors: Array, languageToolApiFailed: Boolean },
	data() {
		return {
			errorTotal: null,
			items: [],
			dialog: false,
		};
	},
	watch: {
		errors: {
			handler() {
				this.initializeItems();
				this.errorTotal = this.errors.length;
			},
		},
	},
	computed: {
		belowDesktop() {
			return window.innerWidth <= 648;
		},
		trackProps() {
			return {
				label: 'Orthographe',
				labelColor: 'var(--tag-violet-primary)',
				bgClass: 'bg-tag-violet-secondary',
				headerScore: '0',
				headerScoreText: 'faute cette fois !',
			};
		},
	},
	methods: {
		handlecontentClick(index) {
			this.$refs.targetButton[index].click();
		},
		toggleOrthographeErrorsDialog() {
			this.dialog = !this.dialog;
		},
		initializeItems() {
			// Transform array with limitation
			const SUGGESTION_LIMIT_MAX = 4;
			const errorsWantedToshow = this.errors.filter((e) => {
				return CATEGORIES_DONT_WANT_TO_SHOW.includes(e.category) === false;
			});
			this.items = errorsWantedToshow.map((error, index) => {
				return {
					indexMatch: index,
					type: CATEGORY_MAPPING[error.category] ? CATEGORY_MAPPING[error.category].label : error.category,
					explanation: error.message,
					suggestions: error.replacements.slice(0, SUGGESTION_LIMIT_MAX),
				};
			});
		},
		handleSelectItem(item) {
			this.$emit('onErrorSelect', item);
		},
	},
};
</script>

<style lang="scss" scoped>
.orthographe {
	.orthographe-items-wrapper {
		display: flex;
		flex-direction: column;
		gap: 12px;
		max-height: 445px;
		overflow: scroll;
		overflow-x: hidden;

		.suggestion-list {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
		}

		&::-webkit-scrollbar {
			width: 0px;
		}

		&::-webkit-scrollbar-track,
		&::-webkit-scrollbar-thumb {
			background: transparent;
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: transparent;
		}

		.orthographe-item {
			gap: 10px;
			flex-direction: column;
			background-color: white;
			font-size: 12px;
			h4::before {
				content: '';
				display: inline-block;
				width: 8px;
				height: 8px;
				background-color: var(--tag-violet-primary);
				border-radius: 50%;
				margin-right: 5px;
			}

			h3,
			p {
				font-size: 14px;
			}

			.content-block {
				display: flex;
				flex-direction: column;
				gap: 8px;
			}
		}
	}
}

.v-expansion-panel-header {
	min-height: 30px;
}

.v-expansion-panel-header--active {
	min-height: 40px;
}

::v-deep {
	.v-expansion-panel-content__wrap {
		padding: 0px;
	}
}
</style>
